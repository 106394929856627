<template>
  <div style="height: 100%">
    <div class="earlyWarning">
      <div class="earlyWarning_left">
        <div class="box">
          <CashSupervisionLeft :refresh-key="refreshKey"></CashSupervisionLeft>
        </div>
        <div class="box" style="perspective-origin: left -111px">
          <TransactionRecheckLeft
            :refresh-key="refreshKey"
          ></TransactionRecheckLeft>
        </div>
      </div>
      <div class="earlyWarning_center">
        <div class="img_center">
          <TotalLable
            type="untreated"
            style="cursor: pointer"
            :value="
              formatString(
                getWarningTotal('unhandleWarningCount') &&
                  getWarningTotal('unhandleWarningCount').count
              )
            "
            @click.native="
              $refs.EarlyWarningTableDialog.openDialog({
                query: {
                  status: '02',
                },
                fixedParam: {
                  handleFlag: ['01'],
                },
              })
            "
          />
          <TotalLable
            style="cursor: pointer"
            :value="
              formatString(
                getWarningTotal('handleWarningCount') &&
                  getWarningTotal('handleWarningCount').count
              )
            "
            @click.native="
              $refs.EarlyWarningTableDialog.openDialog({
                query: {
                  status: '02',
                },
                fixedParam: {
                  handleFlag: ['02', '03'],
                },
              })
            "
          />
        </div>
        <div class="allOutlets">
          <AllOutletsFooter
            v-if="!isSubbranch"
            :refresh-key="refreshKey"
          ></AllOutletsFooter>
          <template v-else>
            <NoVieweTask :refresh-key="refreshKey"></NoVieweTask>
            <SubOutletsFooter :refresh-key="refreshKey"></SubOutletsFooter>
          </template>
        </div>
      </div>
      <div class="earlyWarning_right">
        <div class="box">
          <ServiceSupervisionRight
            :refresh-key="refreshKey"
          ></ServiceSupervisionRight>
        </div>
        <div class="box" style="perspective-origin: right -111px">
          <!--          <TaskRight :refresh-key="refreshKey"></TaskRight>-->
          <SafeRight></SafeRight>
        </div>
      </div>
    </div>
    <EarlyWarningTableDialog
      ref="EarlyWarningTableDialog"
    ></EarlyWarningTableDialog>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import {formatString} from '@/views/screen/config';
import EarlyWarningTableDialog from './modules/EarlyWarningTableDialog.vue';

export default {
  //组件注册
  components: {
    CashSupervisionLeft: () => import('./modules/CashSupervisionLeft.vue'),
    TransactionRecheckLeft: () =>
      import('./modules/TransactionRecheckLeft.vue'),
    ServiceSupervisionRight: () =>
      import('./modules/ServiceSupervisionRight.vue'),
    // TaskRight: () => import('./modules/TaskRight.vue'),
    SafeRight: () => import('./modules/safeRight'),
    AllOutletsFooter: () => import('./modules/AllOutletsFooter.vue'),
    NoVieweTask: () => import('./modules/NoVieweTask.vue'),
    SubOutletsFooter: () => import('./modules/SubOutletsFooter.vue'),
    TotalLable: () => import('./components/TotalLable.vue'),
    EarlyWarningTableDialog,
  },
  //组件传值
  props: {
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  //计算属性
  computed: {
    ...mapState({
      isSubbranch: (state) => state.screenStore.isSubbranch,
      globalQuery: (state) => state.screenStore.globalQuery,
      warningTotal: (state) => state.screenStore.warningTotal,
    }),
    getWarningTotal() {
      return (code) =>
        this.warningTotal.down &&
        this.warningTotal.down.find((e) => e.code == code);
    },
  },
  //属性监听
  watch: {
    globalQuery: {
      deep: true,
      handler: 'getMonitordata',
    },
    refreshKey: {
      handler: 'getMonitordata',
    },
  },
  //DOM访问
  mounted() {
    if (Object.keys(this.globalQuery).length) {
      this.getMonitordata();
    }
    this.$bus.$on('warning-success-home', this.getMonitordata);
  },
  //保存方法
  methods: {
    formatString,
    ...mapMutations(['screenStore/SET_warningTotal']),
    async getMonitordata() {
      const data = await this.$http.get(
        `/${this.$businessISG}/monitor/home/monitordata-stat`,
        this.globalQuery
      );
      if (data.code == 200 && data.data)
        this['screenStore/SET_warningTotal'](data.data[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.earlyWarning {
  display: flex;
  height: 100%;

  .earlyWarning_left,
  .earlyWarning_right {
    flex: 0 0 26%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .box {
      perspective: 200px;
      height: 311px;
      display: flex;

      .layoutBox {
        flex: 1;
        overflow: hidden;
      }
    }
  }

  .earlyWarning_center {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 38px;

    .img_center {
      position: relative;
      flex: 1;
      background: url('../../../assets/screen/earlyWarning/img_center.png');
      background-position: center;
      margin-right: -24px;
    }

    .allOutlets {
      position: relative;
      flex: 0 0 260px;
      z-index: 1;

      .layoutBox {
        position: absolute;
        top: 0;
        left: -16%;
        width: 134%;
        height: 260px;
      }
    }
  }
}

@media screen and (max-height: 1000px) {
  ::v-deep .img_center {
    background-size: 150% 150% !important;
  }
}
</style>
