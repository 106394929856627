var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"earlyWarning"},[_c('div',{staticClass:"earlyWarning_left"},[_c('div',{staticClass:"box"},[_c('CashSupervisionLeft',{attrs:{"refresh-key":_vm.refreshKey}})],1),_c('div',{staticClass:"box",staticStyle:{"perspective-origin":"left -111px"}},[_c('TransactionRecheckLeft',{attrs:{"refresh-key":_vm.refreshKey}})],1)]),_c('div',{staticClass:"earlyWarning_center"},[_c('div',{staticClass:"img_center"},[_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"type":"untreated","value":_vm.formatString(
              _vm.getWarningTotal('unhandleWarningCount') &&
                _vm.getWarningTotal('unhandleWarningCount').count
            )},nativeOn:{"click":function($event){return _vm.$refs.EarlyWarningTableDialog.openDialog({
              query: {
                status: '02',
              },
              fixedParam: {
                handleFlag: ['01'],
              },
            })}}}),_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"value":_vm.formatString(
              _vm.getWarningTotal('handleWarningCount') &&
                _vm.getWarningTotal('handleWarningCount').count
            )},nativeOn:{"click":function($event){return _vm.$refs.EarlyWarningTableDialog.openDialog({
              query: {
                status: '02',
              },
              fixedParam: {
                handleFlag: ['02', '03'],
              },
            })}}})],1),_c('div',{staticClass:"allOutlets"},[(!_vm.isSubbranch)?_c('AllOutletsFooter',{attrs:{"refresh-key":_vm.refreshKey}}):[_c('NoVieweTask',{attrs:{"refresh-key":_vm.refreshKey}}),_c('SubOutletsFooter',{attrs:{"refresh-key":_vm.refreshKey}})]],2)]),_c('div',{staticClass:"earlyWarning_right"},[_c('div',{staticClass:"box"},[_c('ServiceSupervisionRight',{attrs:{"refresh-key":_vm.refreshKey}})],1),_c('div',{staticClass:"box",staticStyle:{"perspective-origin":"right -111px"}},[_c('SafeRight')],1)])]),_c('EarlyWarningTableDialog',{ref:"EarlyWarningTableDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }